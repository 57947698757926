.r_searchCol{
    text-align: center;
}

.r_searchInput{
    width: 75% !important;
}

.r_dataTable{
    display: flex;
    justify-content: center;
}

.r_dataTable .ant-table-wrapper{
    width: 92%;
} 

.r_infoSection{
    padding-left: 4.1%;
    padding-right: 4.1%;
    margin-bottom: 20px;
}

.ant-modal-title{
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.ant-select.r_searchInput.ant-select-multiple.ant-select-show-search{
    text-align: left;
}