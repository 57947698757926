.r_headerWrapper{
    background-color: rgba(64, 1, 32, 0.4);
    padding:0px;
    height: auto;
    z-index: 2;
}

.r_menu{
    background-color: inherit;
}

.r_menu .ant-menu-submenu-title{
    color:#FFF;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.ant-menu-submenu-popup > .ant-menu{
    background-color: #fcb300;
    color: #FFF;
    text-align: right;
}

.ant-menu-item a, .ant-menu-horizontal > .ant-menu-item a{
    color: #FFF;
}

@media screen and (max-width: 650px) {
    .r_headerWrapper .logo{
        float: inherit !important;
    }
    .r_headerWrapper ul{
        background-color:#400120eb;
    }
}
