.styles_react-code-input__CRulA > input:focus{
    border: 1px solid #400120 !important;
    caret-color: #400120 !important;
}

.styles_react-code-input-container__tpiKG{
    width: 100% !important;
}

.steps-action{
    margin-top: 3%;
}
