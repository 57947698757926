.r_footer_wrapper{
    padding: 0px;
}

.r_footerSection{
    color:#FFF;
    padding: 24px 15%;
}

.r_footer{
    background-color: #400120;
}

.r_footerIcon{
    margin-left: 20px;
    color: #606060;
    font-size: 14px;
}

.r_footerSection a{
    color: #fff;
    font-size: 13px;
}

.r_footer a:hover{
    color:#ffb606;
}

.r_copyRight a:hover{
    text-decoration: underline;
}

.r_footer a.contatInfo{
    font-size: 15px;
}

.r_footer h4{
    font-weight: 700;
    text-transform: uppercase;
    color:#FFF;
}

.r_footer ul{
    margin-top: 25px;
}

.r_footerSection ul{
    list-style: none;
    padding-left: 0px;
}

.r_footerSection li{
    margin-bottom: 5px;
}

.r_copyRight{
    background-color: #fcb300;
    padding-top: 34px;
    padding-bottom: 16px;
}

.r_copyRight li{
    display: inline-block;
    margin-left: 15px;
}

.r_copyRight ul{
    text-align: left;
}

.r_footerSocialBtn{
    width: 32px;
    height: 32px;
    line-height: 30px;
    border: 1px solid #999;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    margin-right: 10px;
    -webkit-transition: all .3s;
    -khtml-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.r_footerSocialBtn_FB:hover{
    background-color: #3b5998;
    border-color: #3b5998;
}

.r_footerSocialBtn_TW:hover{
    background-color: #00aced;
    border-color: #00aced;
}

.r_footerSocialBtn_GP:hover{
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.r_footerSocialBtn a {
    font-size: 16px;
}

.r_footerSocial{
    margin-top: 30px;
}

.r_footerSocialBtn a:hover{
    color:#FFF;
}