.r_mainImg{
    max-height: 300px;
    margin-top: -84px;
}

.r_mainImg img{
    width: 100%;
    object-fit: cover;
    max-height: 300px;
}

.r_mainLayout .ant-layout-header .ant-menu{
    border-bottom: 0px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
    justify-content: center !important;
}

.r_mainTabs .ant-tabs-tab-btn{
    font-size:22px;
}

.r_mainTabs{
    margin-top: 5%;
}

.r_mainLayout .ant-layout-content{
    background-color: #FFF;
}

.r_userInputSpan{
    margin-left: 1px;
    font-size: 14px;
    margin-bottom: 6px;
    display: block;
    margin-top: 2%;
}

.r_mainLayout .site-layout-content{
    padding:0 50px;
}

#r_responsiveData{
    display:none;
}

@media screen and (max-width: 650px) {
    .r_mainLayout .site-layout-content{
        padding:0px !important;
    }
    .r_mainTabs .ant-tabs-tab-btn{
        font-size:13px;
    }
    #r_tableData, .ant-table-wrapper{
        display: none;
    }
    #r_responsiveData{
        display:flex;
        margin-bottom: 20px;
    }
    #r_responsiveData .ant-col{
        text-align: center;
        border:1px solid #e0e0e0
    }
    #r_responsiveData .title{
        font-weight: bolder;
        background-color: #400120b5;
        color: #FFF;
    }
    .ant-steps-item.ant-steps-item-wait.ant-steps-item-custom.ant-steps-item-active,
    .ant-steps-item.ant-steps-item-wait.ant-steps-item-custom,
    .ant-steps-item.ant-steps-item-finish.ant-steps-item-custom{
        padding-left: 17px !important;
    }
    .styles_react-code-input__CRulA > input{
        width: 45px !important;
        height: 45px !important;
    }
}

