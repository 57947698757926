#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.r_dashboard_Logo{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;;
}

.r_adminDashboard{
    height: 100vh;
}